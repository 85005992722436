<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(guardar)">
      <b-row align-v="center">
        <b-form-group
          class="col-md-6"
          label="Nombre de Fantasía"
          label-for="Nombre de Fantasía"
        >
          <ValidationProvider
            name="Nombre de Fantasía"
            rules="required|min:1|max:250"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="form.fantasy_name"
              type="text"
              placeholder="Nombre de Fantasía"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            ></b-form-input>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Razón Social"
          label-for="Razón Social"
        >
          <ValidationProvider
            name="Razón Social"
            rules="required|min:3|max:250"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="form.business_name"
              type="text"
              placeholder="Razón Social"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            ></b-form-input>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Código De Cuenta"
          label-for="Código De Cuenta"
        >
          <ValidationProvider
            name="Código De Cuenta"
            rules="min:4|max:30"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="form.code_account"
              type="text"
              placeholder="Código De Cuenta"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            ></b-form-input>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="col-md-6" label="RUT" label-for="rut">
          <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
            <b-form-input
              v-model="form.rut"
              :value="validate(form.rut)"
              type="text"
              placeholder="RUT"
              :class="
                errors.length > 0 || validateRut == false ? ' is-invalid' : ''
              "
              v-mask="['########-X', '#######-X']"
            ></b-form-input>
            <small class="text-danger" v-if="validateRut == false"
              >RUT invalido</small
            >
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="col-md-6" label="Giro" label-for="giro">
          <ValidationProvider
            name="Giro"
            rules="required|min:3|max:250|alpha_spaces"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="form.gire"
              type="text"
              placeholder="Ingrese el Giro"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            ></b-form-input>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="col-sm-6" label-for="comuna" label="Comuna:">
          <ValidationProvider
            name="Comuna"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="form.comuna"
              placeholder="Seleccionar..."
              label="name"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              :options="getComunas"
              id="comuna"
            >
              <template #no-options="{}"> Sin Resultados... </template>
            </v-select>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="col-md-6" label="Dirección" label-for="dirección">
          <ValidationProvider
            name="dirección"
            rules="required:false|min:4|max:50"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="form.address"
              type="text"
              placeholder="Dirección"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            ></b-form-input>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </b-form-group>
      </b-row>
      <b-button type="submit" variant="primary" class="mr-2"
        >{{ detalls ? "Editar registro" : "Guardar Registro" }}
      </b-button>
      <b-button
        :to="{ name: 'provedores.list' }"
        variant="none"
        class="iq-bg-danger"
        >Cancelar</b-button
      >
    </form>
  </ValidationObserver>
</template>
<script>
import { core } from "@/config/pluginInit";
import verificador from "verificador-rut";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    detalls: { defautl: null },
  },
  async created() {
    await this.listComunas();
    await this.listProviders();
    if (this.$route.params.id) {
      this.form = {
        id: this.detalls.id,
        fantasy_name: this.detalls.fantasy_name,
        business_name: this.detalls.business_name,
        code_account: this.detalls.code_account,
        rut: this.detalls.rut,
        comuna: this.detalls.comuna,
        gire: this.detalls.gire,
        address: this.detalls.address,
      };
    } else {
      this.form = {
        id: "",
        fantasy_name: "",
        business_name: "",
        code_account: "",
        rut: "",
        comuna: "",
        gire: "",
        address: "",
      };
    }
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      form: {
        id: "",
        fantasy_name: "",
        business_name: "",
        code_account: "",
        rut: "",
        comuna: "",
        gire: "",
        address: "",
      },
      validateRut: true,
    };
  },
  methods: {
    validate(rut) {
      if (verificador(rut)) {
        this.validateRut = true;
      } else {
        this.validateRut = false;
      }
    },
    ...mapActions({
      listProviders: "providers/fechProviders",
      registerProviders: "providers/registerProviders",
      listComunas: "tool/fetchComunas",
    }),
    async guardar() {
      try {
        const {
          id,
          fantasy_name,
          business_name,
          code_account,
          rut,
          comuna,
          gire,
          address,
        } = this.form;
        const payload = {
          id: id,
          fantasy_name: fantasy_name,
          business_name: business_name,
          code_account: code_account,
          rut: rut,
          comuna: comuna.id,
          gire: gire,
          address: address,
        };
        const res = await this.registerProviders(payload);
        this.$router.replace("/provedores/list");
        core.showSnackbar("success", res);
      } catch (error) {
        console.log(error);
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getComunas: "tool/getComunas",
    }),
  },
};
</script>