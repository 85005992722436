<template>
	<b-container v-can="'provider.update'">
		<b-row>
			<b-col lg="12">
				<div class="iq-edit-list-data">
					<iq-card>
						<template v-slot:headerTitle>
							<h4 class="card-title">Editar Proveedores</h4>
						</template>
						<template v-slot:body>
							<createOrUpdate :detalls="getProvider"></createOrUpdate>
						</template>
					</iq-card>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	import createOrUpdate from './createOrUpdate'
  import {mapGetters, mapActions} from 'vuex'
	export default {
		name: 'EditarProvedores',
		components:{
			createOrUpdate
		},
    async created() {
		this.$store.commit("SET_BREADCRUMB", this.items);
      await this.showProvider(this.$route.params.id)
    },
		data () {
			return {
				items: [
				{
					html: 'Inicio',
					to: '/'
				},
				{
					text: 'Listado de proveedores',
					to: '/provedores/list'
				},
				{
					text: 'Editar proveedor',
					active: true
				}
				]
			}
		},
    methods: {
      ...mapActions({
        showProvider: 'providers/showProviders'
      })
    },
    computed: {
      ...mapGetters({
        getProvider: 'providers/getProvider'
      })
    }
	}
</script>
<style>
.vs__clear {
	margin-right: 5px !important;
	margin-bottom: 3px !important;
}
</style>
