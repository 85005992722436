var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.guardar)}}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Nombre de Fantasía","label-for":"Nombre de Fantasía"}},[_c('ValidationProvider',{attrs:{"name":"Nombre de Fantasía","rules":"required|min:1|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Nombre de Fantasía"},model:{value:(_vm.form.fantasy_name),callback:function ($$v) {_vm.$set(_vm.form, "fantasy_name", $$v)},expression:"form.fantasy_name"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Razón Social","label-for":"Razón Social"}},[_c('ValidationProvider',{attrs:{"name":"Razón Social","rules":"required|min:3|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Razón Social"},model:{value:(_vm.form.business_name),callback:function ($$v) {_vm.$set(_vm.form, "business_name", $$v)},expression:"form.business_name"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Código De Cuenta","label-for":"Código De Cuenta"}},[_c('ValidationProvider',{attrs:{"name":"Código De Cuenta","rules":"min:4|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Código De Cuenta"},model:{value:(_vm.form.code_account),callback:function ($$v) {_vm.$set(_vm.form, "code_account", $$v)},expression:"form.code_account"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"RUT","label-for":"rut"}},[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['########-X', '#######-X']),expression:"['########-X', '#######-X']"}],class:errors.length > 0 || _vm.validateRut == false ? ' is-invalid' : '',attrs:{"value":_vm.validate(_vm.form.rut),"type":"text","placeholder":"RUT"},model:{value:(_vm.form.rut),callback:function ($$v) {_vm.$set(_vm.form, "rut", $$v)},expression:"form.rut"}}),(_vm.validateRut == false)?_c('small',{staticClass:"text-danger"},[_vm._v("RUT invalido")]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Giro","label-for":"giro"}},[_c('ValidationProvider',{attrs:{"name":"Giro","rules":"required|min:3|max:250|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Ingrese el Giro"},model:{value:(_vm.form.gire),callback:function ($$v) {_vm.$set(_vm.form, "gire", $$v)},expression:"form.gire"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"label-for":"comuna","label":"Comuna:"}},[_c('ValidationProvider',{attrs:{"name":"Comuna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Seleccionar...","label":"name","options":_vm.getComunas,"id":"comuna"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados... ")]}}],null,true),model:{value:(_vm.form.comuna),callback:function ($$v) {_vm.$set(_vm.form, "comuna", $$v)},expression:"form.comuna"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Dirección","label-for":"dirección"}},[_c('ValidationProvider',{attrs:{"name":"dirección","rules":"required:false|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"type":"text","placeholder":"Dirección"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.detalls ? "Editar registro" : "Guardar Registro")+" ")]),_c('b-button',{staticClass:"iq-bg-danger",attrs:{"to":{ name: 'provedores.list' },"variant":"none"}},[_vm._v("Cancelar")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }